<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><v-app id="inspire">
	<v-app-bar app color="white" flat><v-container class="py-0 fill-height">
		<div class="k-logo mr-4">
			<img :src="logo_src" :style="logo_style" />
			<div>{{site_name}}</div>
		</div>

		<v-spacer></v-spacer>

		<v-responsive max-width="260"><v-text-field dense flat hide-details rounded solo-inverted xappend-icon="fas fa-search"></v-text-field></v-responsive>
		<v-btn small fab color="#ccc" class="ml-3 elevation-0" @click="settings_dialog_open=true"><v-icon>fas fa-bars</v-icon></v-btn>
	</v-container></v-app-bar>

	<v-main class="grey lighten-3">
		<v-container><v-row>
			<v-col cols="2">
				<v-sheet rounded="lg">
					<v-list color="transparent">
						<v-list-item v-for="n in 5" :key="n" link>
							<v-list-item-content>
								<v-list-item-title>List Item {{ n }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-divider class="my-2"></v-divider>

						<v-list-item link color="grey lighten-4">
							<v-list-item-content>
								<v-list-item-title @click="refresh_page">
									Refresh
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-sheet>
			</v-col>

			<v-col><v-sheet min-height="70vh" rounded="lg" class="px-4 py-1">
				<h2 v-html="page_header"></h2>
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
				<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
				<p class="text-center mt-4 pa-4" style="border-top:1px solid #ccc">
					<v-btn small color="#333" dark class="ma-1" @click="show_framework('switcher', '')"><v-icon small class="mr-2">fas fa-arrow-right-arrow-left</v-icon>Choose a CASE Framework</v-btn>
					<v-btn small color="#333" dark class="ma-1" @click="show_framework(framework_identifier, '')"><v-icon small class="mr-2">fas fa-map</v-icon>Open to Framework</v-btn>
					<v-btn small color="#333" dark class="ma-1" @click="show_framework(framework_identifier, item_identifier)"><v-icon small class="mr-2">fas fa-circle-dot</v-icon>Open to Standard</v-btn>
				</p>
				<div class="d-flex align-center mt-1 mb-4">
					<v-spacer/>
					<span class="mr-2">Search framework:</span><v-responsive max-width="380"><v-text-field v-model="search_terms" dense flat hide-details rounded outlined solo append-icon="fas fa-search" @keyup.enter="search_framework" @click:append="search_framework"></v-text-field></v-responsive>
					<v-spacer/>
				</div>
				<p class="text-center">
					<v-btn small color="#333" dark class="ma-1" @click="show_chooser(framework_identifier)"><v-icon small class="mr-2">far fa-square</v-icon>Choose Standards</v-btn>
				</p>
				<div v-for="(item) in selected_items" :key="item.cfitem.identifier" class="d-flex align-start my-2" style="cursor:pointer" @click="show_framework(item.framework_identifier, item.cfitem.identifier)">
					<v-icon small color="#333" style="margin-top:4px">fas fa-circle-dot</v-icon>
					<b v-if="item.cfitem.humanCodingScheme" class="ml-2" v-html="item.cfitem.humanCodingScheme"></b>
					<div class="ml-2" v-html="render_statement(item.cfitem.fullStatement)"></div>
				</div>
			</v-sheet></v-col>
		</v-row></v-container>
	</v-main>
	<SatchelInline ref="satchel" />

	<v-dialog v-model="settings_dialog_open" max-width="800" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b>Title</b></v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">
				<v-text-field class="my-4" outlined hide-details label="Logo URL" v-model="logo_src" name="no-autocomplete"></v-text-field>
				<v-text-field class="my-4" outlined hide-details label="Logo CSS style" v-model="logo_style" name="no-autocomplete"></v-text-field>
				<v-text-field class="my-4" outlined hide-details label="Site Name" v-model="site_name" name="no-autocomplete"></v-text-field>
				<v-text-field class="my-4" outlined hide-details label="Page Header" v-model="page_header" name="no-autocomplete"></v-text-field>
				<v-text-field class="my-4" outlined hide-details label="Sample framework identifier" v-model="framework_identifier" name="no-autocomplete"></v-text-field>
				<v-text-field class="my-4" outlined hide-details label="Sample item identifier" v-model="item_identifier" name="no-autocomplete"></v-text-field>
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-spacer></v-spacer>
				<v-btn color="#333" dark @click="settings_dialog_open=false">Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

</v-app></template>

<script>
import SatchelInline from './components/SatchelInline'
import { mapState, mapGetters } from 'vuex'

export default {
	name: 'App',
	components: { SatchelInline },
	data() { return {
		selected_items: [],
		settings_dialog_open: false,
		search_terms: '',
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		logo_src: {
			get() { return this.$store.state.lst.logo_src },
			set(val) { this.$store.commit('lst_set', ['logo_src', val]) }
		},
		logo_style: {
			get() { return this.$store.state.lst.logo_style },
			set(val) { this.$store.commit('lst_set', ['logo_style', val]) }
		},
		site_name: {
			get() { return this.$store.state.lst.site_name },
			set(val) { this.$store.commit('lst_set', ['site_name', val]) }
		},
		page_header: {
			get() { return this.$store.state.lst.page_header },
			set(val) { this.$store.commit('lst_set', ['page_header', val]) }
		},
		framework_identifier: {
			get() { return this.$store.state.lst.framework_identifier },
			set(val) { this.$store.commit('lst_set', ['framework_identifier', val]) }
		},
		item_identifier: {
			get() { return this.$store.state.lst.item_identifier },
			set(val) { this.$store.commit('lst_set', ['item_identifier', val]) }
		},
	},
	created() {
		window.vapp = this
	},
	mounted() {
		this.initialize_app()
	},
	methods: {
		initialize_app() {
			console.log('initialize_app')
			this.$store.commit('lst_initialize')
		},
		show_framework(framework_identifier, item_identifier, search_terms) {
			let data = { 
				framework_identifier: framework_identifier, 
				item_identifier: item_identifier, 
				no_framework_reset: true,
			}
			console.log(data)

			vapp.$refs.satchel.execute('show').then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					if (search_terms) {
						vapp.$refs.satchel.execute('search', {search_terms: search_terms})
					}
				})
			})
		},

		show_chooser() {
			let data = { 
				framework_identifier: this.framework_identifier, 
			}

			// add current selected items
			if (this.selected_items.length > 0) {
				data.selected_items = []
				for (let item of this.selected_items) data.selected_items.push(item.cfitem.identifier)
			}

			let show_data = {}

			let chooser_execute = () => {
				vapp.$refs.satchel.execute('chooser', {chooser_mode: true}).then((aligned_item) => {
					// if we already have this item aligned, remove the standard
					let i = this.selected_items.findIndex(o=>o.cfitem.identifier==aligned_item.cfitem.identifier)
					if (i > -1) {
						this.selected_items.splice(i, 1)
						vapp.$refs.satchel.execute('select_item', {val: false, item_identifier: aligned_item.cfitem.identifier})
						chooser_execute()

					} else {
						this.selected_items.push(aligned_item)
						vapp.$refs.satchel.execute('select_item', {val: true, item_identifier: aligned_item.cfitem.identifier})
						chooser_execute()
					}
				})
			}

			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
					chooser_execute()
				})
			})
		},

		search_framework() {
			this.search_terms = $.trim(this.search_terms)
			if (this.search_terms) {
				this.show_framework(this.framework_identifier, this.item_identifier, this.search_terms)
			}
		},

		render_statement(s) {
			return s
		},
		
		refresh_page() { document.location.reload() },
	}
}
</script>

<style lang="scss">
html {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

body {
	font-size:16px;
}

.v-application {
	font-family: $sans-serif-font;
}

.k-logo {
	display:flex;
	align-items:center;
	font-size:32px;
	font-weight:900;
}

</style>
